<template>
  <!-- login START -->
  <div class="login">

    <div class="description">
      <p v-html="`${$t('login.login')}`"></p>
    </div>

    <!-- form:login START -->
    <el-form
      status-icon
      :rules="rules"
      ref="loginForm"
      :model="loginForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="executeRecaptcha"
    >
      <!-- form:login:email START -->
      <el-form-item prop="email">
        <el-input
          inputmode="email"
          :placeholder="`${$t('login.email')} *`"
          v-model="loginForm.email"
          @keyup.enter.native="executeRecaptcha"
        ></el-input>
      </el-form-item>
      <!-- form:login:email END -->

      <!-- form:login:password START -->
      <el-form-item prop="password">
        <el-input
          :type="type"
          :placeholder="`${$t('login.password')} *`"
          v-model="loginForm.password"
          @keyup.enter.native="executeRecaptcha"
          autocomplete="off"
        >
          <i
            slot="suffix"
            class="el-icon-view"
            v-on:click="showHidePassword()"
          ></i>
        </el-input>
      </el-form-item>
      <!-- form:login:password END -->

      <!-- form:login:submit START -->
      <el-form-item>
        <el-button
          class="btn-default"
          type="primary"
          v-on:click="executeRecaptcha"
          v-if="!loadingVisible"
        >{{ $t('login.loginheadline') }}</el-button>
      </el-form-item>
      <!-- form:login:submit END -->

      <!-- form:login:with-account START -->
      <div
        class="register-with-account"
        v-if="!loadingVisible"
      >
        <span>{{ $t('login.nothaveanaccount') }}?<br></span>
        <router-link
          v-on:click="resetForm('loginForm')"
          :to="'/register'"
        >
          {{ $t('login.register') }}
        </router-link>
      </div>
      <!-- form:login:with-account END -->

      <!-- form:google-recaptcha:submit START -->
      <recaptcha
        ref="recaptcha"
        v-on:verify="verifyForm"
      ></recaptcha>
      <!-- form:google-recaptcha:submit END -->

    </el-form>
    <!-- form:login END -->

    <!-- form:login:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:login:loading END -->
  </div>
  <!-- login END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'sign-in',
  mixins: [titleMixin],
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
    Recaptcha: () => import('../../components/shared/Recaptcha.vue'),
  },
  data() {
    return {
      googleRecaptchaToken: '',
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('login.loginheadline'),

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.login.headline'),
      loadingExcerpt: this.$t('loading.login.excerpt'),

      /**
       * Form (Variables)
       */
      serverErrorMessages: [],
      globalErrorMessage: false,
      type: 'password',

      /**
       * Form (Models) + Pass data to mixin
       */
      messagePassword: this.$t('login.messagepassword'),
      confirmationPassword: this.$t('login.confirmationpassword'),
      loginForm: {
        email: '',
        password: '',
      },
      /**
       * Form (Rules - Validation)
       */
      rules: {
        email: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            type: 'email',
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  created() {
    if (localStorage.getItem('lang') && localStorage.getItem('eToken') && localStorage.getItem('eToken') !== 'undefined') {
      this.loginByToken();
    }
  },
  methods: {
    submitForm(formName) {
      if (formName === 'loginForm') {
        const formData = new FormData();
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;
            /**
             * Recaptcha
             */
            formData.append('g-recaptcha-response', this.googleRecaptchaToken);
            /**
             * Locale
             */
            formData.append('locale', localStorage.getItem('lang'));
            /**
             * Personal Data
             */
            formData.append('email', this.loginForm.email);
            formData.append('password', this.loginForm.password);

            // Display the key/value pairs
            /* [...formData.entries()].forEach((row) => {
              console.log(`${row}`);
            }); */

            this.$http.post(`${process.env.VUE_APP_BASE_URL}login`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  const user = response.data;
                  const auth = user.token;
                  const eToken = user.user.encryptedToken;

                  // mutating to store for client rendering &
                  // saving token in cookie for server rendering
                  this.$store.commit('SET_AUTH', auth);
                  localStorage.setItem('auth', auth);

                  // mutating to store for client rendering &
                  // saving user data in cookie for server rendering
                  this.$store.commit('SET_USER', user);
                  localStorage.setItem('user', JSON.stringify(user));
                  localStorage.setItem('timeout', user.timeout);

                  // saving encrypted token in cookie for server rendering
                  this.$store.commit('SET_ETOKEN', eToken);
                  localStorage.setItem('eToken', eToken);

                  // saving info & don't show Welcome Screen again, if once registered/logged in
                  localStorage.setItem('welcomeScreen', false);

                  this.$router.push('/home');
                } else {
                  this.globalErrorMessage = true;
                  this.resetForm.agbs = false;

                  console.log('error register submit!!');
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  // Close loading
                  this.loadingVisible = false;
                  this.errorStatus = error.response.data;
                  this.serverErrorMessages = this.errorStatus.errors;

                  this.serverErrorMessages.forEach((message) => {
                    this.error(`${this.$t(`error.${message.code}`)}`);
                  });
                }
              });
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },

    showHidePassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },

    loginByToken() {
      // populate login form - login should be nice, with all fields populated :)
      const localStorageUser = JSON.parse(localStorage.getItem('user'));
      if (localStorageUser) {
        this.loginForm.email = localStorageUser.user.email;
        this.loginForm.password = 'fakePasswordData'; // fake data to show points
      }

      const formData = new FormData();
      this.loadingVisible = true;
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('lang'));
      /**
       * eToken
       */
      formData.append('token', localStorage.getItem('eToken'));

      this.$http.post(`${process.env.VUE_APP_BASE_URL}loginByToken`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
      })
        .then((response) => {
          if (response.data) {
            const user = response.data;
            const auth = user.token;

            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', auth);
            localStorage.setItem('auth', auth);

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', user);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('timeout', user.timeout);

            this.$router.push('/home');
          } else {
            // Close loading
            this.loadingVisible = false;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            // Close loading
            this.loadingVisible = false;
            this.errorStatus = error.response.data;
            this.serverErrorMessages = this.errorStatus.errors;

            this.serverErrorMessages.forEach((message) => {
              this.error(`${this.$t(`error.${message.code}`)}`);
            });
          }
        });
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },

    verifyForm(googleRecaptchaResponse) {
      if (googleRecaptchaResponse) {
        this.googleRecaptchaToken = googleRecaptchaResponse;
        this.submitForm('loginForm');
      }
    },

    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
  },
};
</script>
